import { Logout, Notification, NotificationNew, User } from "@carbon/icons-react";
import {
    Button,
    ComposedModal,
    Header,
    HeaderContainer,
    HeaderGlobalAction,
    HeaderGlobalBar,
    HeaderMenuButton,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation,
    HeaderSideNavItems,
    ModalBody,
    ModalFooter,
    SideNav,
    SideNavItems,
    SkipToContent,
} from '@carbon/react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalStateManager from "../../components/Modal/index.js";
import { addModal, closeModal, toggleModal } from "../../store/reducers/modalsReducer.js";
import { isAdminThunk, logout } from "../../store/reducers/userReducer.js"
import { getModalById } from "../../Utils/utils.js";

const default_urls = [
    { url: '/dashboard', target: 'in', name: 'Gráficas', active: true },
    { url: '/date-filter', target: 'in', name: 'Nuevo Dashboard', active: true },
    { url: '/admin', target: 'out', name: 'Admin', active: true },
]
const HeaderLayout = () => {
    const navigator = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const dispatchLogout = () => {
        dispatch(closeModal(modalLogout))
        dispatch(logout())
    }
    const user = useSelector((state) => state.user)
    const notifications = useSelector((state) => state.notifications)
    const modal = useSelector((state) => state.modals)
    const modalLogout = 'logout'

    const [navItems, setNavItem] = useState([...default_urls])

    useEffect(() => {
        dispatch(addModal(modalLogout))
    }, [modal])
    useEffect(() => {
        if (!user.isAdmin) {
            dispatch(isAdminThunk()).unwrap().then((e) => {
                let new_urls = [...navItems]
                new_urls[new_urls.length - 1].active = e.is_admin
                setNavItem(() => ([...new_urls]))
            })
        }
    }, [])
    const modalService = ModalStateManager(
        {
            children:
                <>
                    <ComposedModal
                        open={getModalById(modal, modalLogout) ? getModalById(modal, modalLogout).state : false}
                        onClose={() => dispatch(closeModal(modalLogout))} size="md">
                        <ModalBody>
                            <h2>¿Seguro que desea salir de la aplicación?</h2>
                            <br />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                kind="danger"
                                onClick={() =>
                                    dispatch(closeModal(modalLogout))
                                }>
                                Cancelar
                            </Button>
                            <Button
                                kind="primary"
                                onClick={() =>
                                    dispatchLogout()
                                }>
                                Salir
                            </Button>
                        </ModalFooter>
                    </ComposedModal>
                </>
        }
    )
    let headerNavItems = <></>
    let sideNav = <></>
    let headerGlobal = <></>
    if (location.pathname !== '/' && location.pathname !== '/login') {
        headerNavItems = <>
            <HeaderNavigation aria-label="Data Analysis Tool">
                {navItems.map((item) => {
                    return item.active ? (
                        <HeaderMenuItem key={item.name} className="pointer"
                            onClick={() => redirectTo(item)}>{highlight_element(item.name)}</HeaderMenuItem>) : ''
                })
                }
            </HeaderNavigation>
        </>

        sideNav = <>
            <HeaderSideNavItems>
                {navItems.map((item) => {
                    return item.active ? (
                        <HeaderMenuItem key={item.name} className="pointer"
                            onClick={() => redirectTo(item)}>{item.name}</HeaderMenuItem>) : ''
                })}
            </HeaderSideNavItems>
        </>

        headerGlobal = <HeaderGlobalBar>
            <HeaderGlobalAction aria-label="Perfil"
                onClick={() => {
                    navigator('/profile')
                }}><User />
            </HeaderGlobalAction>
            <HeaderGlobalAction aria-label="Notificationes"
                onClick={() => {
                    navigator('/notifications')
                }}>
                {notifications.notifications.length > 0 &&
                    <NotificationNew />}
                {notifications.notifications.length === 0 &&
                    <Notification />}
            </HeaderGlobalAction>
            <HeaderGlobalAction aria-label="Salir"
                onClick={() => dispatch(toggleModal(modalLogout))}><Logout />
            </HeaderGlobalAction>
        </HeaderGlobalBar>
    return (
        <HeaderContainer
            render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                <Header aria-label="MAKEMAKE">
                    <SkipToContent />
                    <HeaderMenuButton
                        aria-label="Open menu"
                        onClick={onClickSideNavExpand}
                        isActive={isSideNavExpanded}
                    />
                    <HeaderName className="pointer" onClick={() => {
                        navigator('/dashboard')
                    }} prefix="MAKEMAKE">
                        <span className={'subtitle'}>Data Analysis Tool</span>
                    </HeaderName>
                    {headerNavItems}
                    {headerGlobal}
                    <SideNav
                        aria-label="Side navigation"
                        expanded={isSideNavExpanded}
                        isPersistent={false}
                        onToggle={onClickSideNavExpand}
                        onOverlayClick={onClickSideNavExpand}
                    >
                        <SideNavItems>
                            {sideNav}
                        </SideNavItems>
                    </SideNav>
                    {modalService}
                </Header>)}
        />
        )
    }
    else {
        return (
            <HeaderContainer
                render={({ }) => (
                    <Header aria-label="MAKEMAKE">
                        <SkipToContent />
                        <HeaderName className="pointer" onClick={() => {
                            navigator('/login')
                        }} prefix="MAKEMAKE">
                            <span className={'subtitle'}>Data Analysis Tool</span>
                        </HeaderName>
                    </Header>)}
            />
        )
    }
    function highlight_element(item_name){
        for (var i = 0; i < navItems.length; i++){
            if (navItems[i].name === item_name && navItems[i].now === true){
                return <span style={{color: '#f5851a'}}><strong><u>{item_name}</u></strong></span>
            }
        }
        return <span>{item_name}</span>
    }

    function redirectTo(item) {
        for (var i = 0; i < navItems.length; i++){
            if (item.name === navItems[i].name){
                navItems[i].now = true;
            }else{
                navItems[i].now = false;
            }
        }
        if (item.target === 'in') {
            navigator(item.url)
        } else {
            window.location.href = `${window.location.origin}${item.url}`
        }
    }
}
export default HeaderLayout
