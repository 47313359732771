import {getAxiosPrivate} from "./common";

const dashboardRequestURL = "/panel/"
const dashboardPanelsRequestURL = "/dashboard/"
const dashboardDateFilterRequestURL = "/newDashboard/"

export const dashboardDataRequest = async (panel, store) => {
    return getAxiosPrivate(store)
        .get(`${dashboardRequestURL}?id=${panel.id}&chart_type=${panel.type}`)
}
export const dashboardPanelsRequest = async (store) => {
    return getAxiosPrivate(store)
        .get(dashboardPanelsRequestURL)
}
export const dashboardDateFilterRequest = async (store, data) => {
    return getAxiosPrivate(store)
        .post(`${dashboardDateFilterRequestURL}?start_date=${data.dates.start_date}&end_date=${data.dates.end_date}`)
}
