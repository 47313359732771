import { Button, InlineLoading } from "@carbon/react";

const ErrorPanel404 = ({ panel, reloadPanel, loading }) => {
    return (
        <div id={'panel_placeholder' + panel.id} className='panel-placeholder'>
            <h2>No existen datos para este gráfico</h2>
            <h3>{panel.name}</h3>
            <div className={'reload-holder'}>
            </div>
        </div>
    )
}
export default ErrorPanel404;
