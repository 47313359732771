import { ArrowDown, FaceDizzy } from "@carbon/icons-react";
import { Accordion, AccordionItem, Content, Loading } from "@carbon/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataSourceTable from "../../components/DataIngressSection/DataSourceTable/index.js";
import DBImport from "../../components/DataIngressSection/DBImport/index.js";
import FileManager from "../../components/DataIngressSection/FileManager/index.js";
import FileUploader from "../../components/DataIngressSection/FileUploader/index.js";
import FilterForm from "../../components/DateFilterSection/FilterForm/index.js";
import { loadDataSources } from "../../Utils/HTTPUtils.js";
import { sourcesToLocal } from "../../Utils/utils.js";

const Index = () => {
    const dispatch = useDispatch();
    const datoolStore = useSelector((state) => state.datool)
    const [loading, setLoading] = useState(false)
    const [sourceItems, setSourceItems] = useState([])

    useEffect(() => {
        setLoading(() => true)
        loadDataSources(setLoading, dispatch)
    }, [])



    return (<Content id="data-analysis-page" className="page-container data-ingress">
        <section className={'section-display'}>
            {loading && <div className="status-container">
                <Loading
                    description="Active loading indicator" withOverlay={false}
                />
            </div>}
            {sourceItems}
        </section>
        <section className={'section-import'}>
            <Accordion align="end" size={'lg'}>
                <AccordionItem title={<h3>Crear nuevo Dashboard</h3>}><FilterForm
                    key={'newItem'}></FilterForm></AccordionItem>
            </Accordion>
        </section>
    </Content>)


}

export default Index
