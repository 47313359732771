import {MeterChart} from "@carbon/charts-react";
import {toNumber} from "../../../../Utils/chartUtils.js";
import PanelDescription from "../../PanelDescription/index.js";
import PanelDescriptionInline from "../../PanelDescriptionInline/index.js";

const Index = ({chart}) => {
    const modalId = `modalInfo#${chart.options.id}-${chart.options.type}`
    const infoModal = <PanelDescription chart={chart} modalId={modalId}></PanelDescription>


    if (chart) {
        chart.data = toNumber(chart)
        
        let max = 0
        chart.data.forEach((e) => {
            max += e.value
        })
        chart.options.meter.proportional.total = max
        return (<div>
            <MeterChart options={chart.options}
                        data={chart.data}></MeterChart>
            <PanelDescriptionInline chart={chart} modalId={modalId}></PanelDescriptionInline>
            {infoModal}
        </div>)
    } else {
        return <div></div>
    }
}

export default Index
