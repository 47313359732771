import {
    Checkbox,
    DatePicker,
    DatePickerInput,
    InlineNotification,
    NotificationActionButton,
    Select,
    SelectItem,
    TimePicker
} from "@carbon/react";
import {useEffect, useState} from "react";
import {v4} from "uuid";
import {backendToDatePickerString, dataSources, datesFilter, toDatePickerString} from "../../Utils/utils.js";

const days = [{name: 'L', key: 'L', state: false, id: 1}, {name: 'M', key: 'M', state: false, id: 2},
    {name: 'M', key: 'MI', state: false, id: 3}, {name: 'J', key: 'J', state: false, id: 4},
    {name: 'V', key: 'V', state: false, id: 5}, {name: 'S', key: 'S', state: false, id: 6}, {
        name: 'D',
        key: 'D',
        state: false,
        id: 7
    }]
const cronDefaults = [
    {key: '@once', name: 'Una vez'},
    {key: '@hourly', name: 'Cada hora'},
    {key: '@daily', name: 'Diario'},
    {key: '@weekly', name: 'Semanal'},
    {key: '@monthly', name: 'Mensual'},
    {key: '@yearly', name: 'Anual'},
]
const Index = ({item, output, setParentState, setParentErrorState, saveCounter}) => {
    let key = v4()
    key = item.id ? item.id : key
    const [disableCronState, setDisableCronState] = useState(false)
    const [crondaysState, setCronDaysState] = useState([...days])
    const [selectionState, setSelectionState] = useState('@once')
    const [itemState, setItemState] = useState({...datesFilter})
    const [errorState, setErrorState] = useState({...datesFilter})
    const [startDateState, setStartDateState] = useState('')
    const [startTimeState, setStartTimeState] = useState('00:00')
    const [endDateState, setEndDateState] = useState('')
    const [endTimeState, setEndTimeState] = useState('00:00')
    const [cronState, setCronState] = useState({minute: '0', hour: '0', month_day: '*', month: '*', week_day: '*'})
    useEffect(() => {
        let localitem = {...item}
        if (Object.keys(item).length === 0) {
            localitem = {...resetCronPicker()}
        }
        setItemState((state) => ({...state, ...localitem}))
    }, [item])
    useEffect(() => {
        if (saveCounter) {
            let localitem = {...item}
            if (Object.keys(item).length === 0) {
                localitem = {...localitem, ...resetCronPicker()}
            }
            setItemState((state) => ({...state, ...localitem}))
        }
    }, [saveCounter])
    useEffect(() => {
        if (Object.keys(itemState).length > 0) {
            extractDateTime()
            
            output('dates', {'start_date': createStartDateTime(), 'end_date': createEndDateTime()},
                setParentState, 
                setParentErrorState)
        }
    }, [itemState])
    useEffect(() => {
        generateCron()
    }, [crondaysState])

    useEffect(() => {
        output('dates', {'start_date': createStartDateTime(), 'end_date': createEndDateTime()},
        setParentState, 
        setParentErrorState)
    }, [startDateState, startTimeState, endDateState, endTimeState, cronState, selectionState])

    function resetCronPicker() {
        const localitem = dataSources
        localitem.periodicity.start_date = toDatePickerString(new Date())
        localitem.periodicity.interval = '@once'
        setStartTimeState(() => '00:00')
        setDisableCronState(() => false)
        return localitem
    }

    const dayItems = days.map((d) => <div className={findDay(d.key).state ? 'day-box selected' : 'day-box'} key={d.key}
                                          onClick={() => selectDay(d)}>{d.name}</div>)
    return (
        <div className='cronPicker'>
            <div>
                <h4>Filtro Inicio</h4>
                <h5>Fecha y hora (24H) donde inicia el filtrado</h5>
                <div className='date-time-picker'>
                    <DatePicker
                        className='date-picker'
                        datePickerType="single"
                        size="lg"
                        locale='es'
                        dateFormat="d/m/Y"
                        maxDate={startDateState}
                        id={"start_date#" + key}
                        onChange={(event) => setStartDateState(() => toDatePickerString(event[0]))}
                        value={startDateState}
                    >
                        <DatePickerInput
                            id={"start_date_input#" + key}
                            labelText="Fecha y hora (24H) donde inicia el filtrado"
                            placeholder="dd/mm/aaaa"
                            size="lg"
                        />
                    </DatePicker>
                    <TimePicker type='time' className='time-picker' size={'lg'} id={"start-time-picker#" + key}
                                value={startTimeState}
                                onChange={(event) => setStartTimeState(() => event.target.value)}>
                    </TimePicker>
                </div>
            <div>
                <br></br>
                <h4>Filtro Fin</h4>
                <h5>Fecha y hora (24H) donde finaliza el filtrado</h5>
                <div className='date-time-picker'>
                    <DatePicker
                        className='date-picker'
                        datePickerType="single"
                        size="lg"
                        locale='es'
                        dateFormat="d/m/Y"
                        minDate={startDateState}
                        id={"end_date#" + key}
                        onChange={(event) => setEndDateState(() => toDatePickerString(event[0]))}
                        value={endDateState}
                    >
                        <DatePickerInput
                            id={"end_date_input#" + key}
                            labelText="Fecha y hora (24H) donde finaliza el filtrado"
                            placeholder="dd/mm/aaaa"
                            size="lg"
                        />
                    </DatePicker>
                    <TimePicker type='time' className='time-picker' size={'lg'} id={"end-time-picker#" + key}
                                value={endTimeState}
                                onChange={(event) => setEndTimeState(() => event.target.value)}>
                    </TimePicker>
                </div>
                </div>
            </div>
        </div>
    )

    function extractDateTime() {
        if (itemState.dates.start_date !== '') {
            let time = startTimeState
            let date = itemState.dates.start_date
            if (itemState.dates.start_date.includes('T')) {
                date = backendToDatePickerString(itemState.dates.start_date.split('T')[0])
                time = itemState.dates.start_date.split('T')[1].split('Z')[0].split(':')
                time = time[0] + ':' + time[1]
            } else {
                time = '00:00'
            }
            setStartTimeState(() => time)
            setStartDateState(() => date)
        } else {
            setStartDateState(() => toDatePickerString(new Date()))
            setStartTimeState(() => '00:00')
        }
        if (itemState.dates.end_date !== '') {
            let time = startTimeState
            let date = itemState.dates.end_date
            if (itemState.dates.end_date.includes('T')) {
                date = backendToDatePickerString(itemState.dates.end_date.split('T')[0])
                time = itemState.dates.end_date.split('T')[1].split('Z')[0].split(':')
                time = time[0] + ':' + time[1]
            } else {
                time = '00:00'
            }
            setEndTimeState(() => time)
            setEndDateState(() => date)
        } else {
            setEndDateState(() => toDatePickerString(new Date()))
            setEndTimeState(() => '00:00')
        }
    }

    function createStartDateTime() {
        return toDatePickerString(startDateState) + ' ' + startTimeState
    }

    function createEndDateTime() {
        return toDatePickerString(endDateState) + ' ' + endTimeState
    }



    function selectDay(date) {
        if (disableCronState) {
            const newD = crondaysState.map((d) => {
                let a = {...d}
                if (d.key === date.key) {
                    a.state = !a.state
                    return a
                }
                return a
            })
            setCronDaysState((state) => [...newD])
        }
    }

    function generateCron() {
        const cron = {minute: '0', hour: '0', month_day: '*', month: '*', week_day: '*'}
        if (crondaysState.some((m) => m.state)) {
            cron.week_day = ''
        }
        for (const [index, d] of crondaysState.entries()) {
            cron.week_day += d.state ? ',' + (index + 1) : '';
        }
        cron.week_day = cron.week_day[0] === ',' ? cron.week_day.slice(1) : cron.week_day
        setCronState((state) => ({...state, ...cron}))
        setErrorState((state) => ({
            ...state, ...{
                interval: false
            }
        }))
    }

    function findDay(key) {
        return crondaysState.find((d) => d.key === key)
    }
}


export default Index
