import {Button, Form, Loading, Select, SelectItem, TextInput} from "@carbon/react";
import {useEffect, useId, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {NotificationModel} from "../../../Models/Notification.js";
import {dashboardDateFilterRequestThunk} from "../../../store/reducers/dashboardReducer.js"
import {sourcesActionsRequestThunk} from "../../../store/reducers/DAToolReducer.js";
import {pushNotification} from "../../../store/reducers/notificationsReducer.js";
import {changeInput, datesFilter, sanitizeItemInputs, toHTTPDownloader, toDatesFilter, validateForm} from "../../../Utils/utils.js";
import DateFilter from "../../DateFilter/index.js";

const Index = ({item, inModal = false, output, parentSubmitState = undefined, setParentSubmitState = undefined}) => {
    const [localState, setLocalState] = useState({...datesFilter})
    const [itemState, setItemState] = useState({...item})
    const [errorState, setErrorState] = useState({...datesFilter})
    const [submitState, setSubmitState] = useState(false)
    const dispatch = useDispatch()
    const inputRef = useRef()

    useEffect(() => {
        if (!item || item === '') {
            setItemState(() => ({...datesFilter}))
        }
        let nItem = {...item}

        setLocalState((state) => ({...state, ...sanitizeItemInputs(nItem)}))
    }, [item])

    useEffect(() => {
        if (itemState !== '' || itemState !== {}) {
            setLocalState((state) => ({...state, ...itemState}))
        }
    }, [itemState])
    useEffect(() => {
        if (inModal) {
            output(localState)
        }
    }, [localState])

    useEffect(()=>{
        if (parentSubmitState && validateForm(localState, setErrorState, ['id', 'file_type'])) {
            output(localState)
        } else {
            if (setParentSubmitState) {
                setParentSubmitState(() => false)
            }
        }
    },[parentSubmitState])

    return (
        <div className="filemanager">
            <div className="inner-content">
                <Form>
                    <div className={'form-content'}>
                        <DateFilter item={itemState} saveCounter={submitState}
                                    output={changeInput} setParentState={setLocalState}
                                    setParentErrorState={setErrorState}></DateFilter>
                        {!inModal &&
                            <Button
                                kind="primary"
                                className={'button-loading'}
                                onClick={(e) => saveDashboard(e)}
                                disabled={submitState}
                            >
                                Guardar

                                {submitState &&
                                    <Loading
                                        description="Active loading indicator" withOverlay={false}
                                        small/>
                                }
                            </Button>}
                    </div>
                </Form>
            </div>
        </div>
    )

    function saveDashboard(e) {
        setSubmitState(() => true)
        e.preventDefault()
        if (validateForm(localState, setErrorState, ['id', 'file_type', 'global_type'])) {
            const data = toDatesFilter(localState)
            setLocalState((state) => ({...state}))
            dispatch(dashboardDateFilterRequestThunk({data})).then((r) => { // cambiar endpoint back
                if (r.error) {
                    dispatch(pushNotification(new NotificationModel('Error al filtrar el dashboard',
                        `HTTP error: ${r.payload.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                        'error').toJson()))
                    setLocalState(() => ({...sanitizeItemInputs(datesFilter)}))
                }
                setSubmitState(() => false)
            }).finally(() => {
                setLocalState(() => ({...sanitizeItemInputs(datesFilter)}))
            })
        } else {
            setSubmitState(() => false)
        }
    }

}


export default Index
