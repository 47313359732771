import { ExperimentalChoroplethChart } from "@carbon/charts-react";
import { toNumber } from "../../../../Utils/chartUtils.js";
import PanelDescription from "../../PanelDescription/index.js";
import PanelDescriptionInline from "../../PanelDescriptionInline/index.js";
import geoData from "./europe.json";

const Index = ({ chart }) => {
    const modalId = `modalInfo#${chart.options.id}-${chart.options.type}`
    const infoModal = <PanelDescription chart={chart} modalId={modalId}></PanelDescription>

    if (chart) {
        chart.data = toNumber(chart)
        chart.options.geoData = geoData 
        
        console.log(chart)
        return (<div>
            <ExperimentalChoroplethChart
                data={chart.data}
                options={chart.options}
            ></ExperimentalChoroplethChart>

            <PanelDescriptionInline chart={chart} modalId={modalId}></PanelDescriptionInline>
            {infoModal}
        </div>)
    } else {
        return <div></div>
    }
}

export default Index
