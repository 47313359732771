import { Content } from "@carbon/react";
import { useEffect } from "react";
import { useSelector } from 'react-redux'

import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { checkToken } from "./apiClient/common.js";
import './App.scss';
import RouteGuard from "./Guards/RouteGuard.js";
import FooterLayout from "./Layouts/Footer/index.js"
import HeaderLayout from "./Layouts/Header/index.js"
import NotificationsLayout from "./Layouts/NotificationsLayout/index.js";
import Dashboard from "./Pages/Dashboard/index.js";

import DataAnalysis from "./Pages/DataIngress/index.js";
import DateFilter from "./Pages/DateFilters/index.js"
import DataProcessSection from "./Pages/DataProcess/index.js";
import DataProcess from "./components/DataProcessSection/DataProcess/index.js";
import Login from "./Pages/Login/index.js";
import Notifications from "./Pages/Notifications/index.js";
import ProfileLayout from "./Pages/Profile/index.js";

function App() {
    const userState = useSelector((state) => state.user)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (location.pathname !== "/login" && !checkToken(userState.token_refresh)) {
            navigate('/login')
        }
    })

    return (
        <div className="main-container">
            <HeaderLayout></HeaderLayout>
            <NotificationsLayout></NotificationsLayout>
            <Content className="container inner-main-container">
                <Routes>
                    <Route exact path="/login" element={<Login />} />
                    <Route path='/*' element={
                        <RouteGuard userState={userState}>
                            <Routes>
                                <Route path="/" element={<Navigate to="/dashboard" />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/date-filter" element={<DateFilter />} />
                                <Route path="/data-process" element={<DataProcessSection />} />
                                <Route path="/data-process/pipelines" element={<DataProcess />} />
                                <Route path="/profile"
                                    element={<ProfileLayout />} />
                                <Route exact path="/notifications"
                                    element={<Notifications />} />
                                <Route exact path="/*"
                                    element={<Navigate to="/dashboard" />} />
                            </Routes>
                        </RouteGuard>

                    }>
                    </Route>
                </Routes>
            </Content>
            <FooterLayout />
        </div>
    );
}

export default App;
