import {useEffect, useState} from "react";
import {Column, Grid, Loading, Select, SelectItem, Button} from "@carbon/react";
import {useDispatch, useSelector} from "react-redux";
import {dashboardDataRequestThunk, dashboardOptionsRequestThunk} from "../../store/reducers/dashboardReducer";
import {ChartModel} from "../../Models/ChartModel";
import {FaceDizzy, Restart} from "@carbon/icons-react";
import {chartChooser, optionsList, prepareChart} from "../../Utils/chartUtils";
import {useHasChanged} from "../../Utils/utils";
import {pushNotification} from "../../store/reducers/notificationsReducer";
import {NotificationModel} from "../../Models/Notification";
import PanelPlaceholder from "../../components/Panels/PanelPlaceholder";
import ErrorPanel from "../../components/Panels/ErrorPanel";
import ErrorPanel404 from "../../components/Panels/ErrorPanel404";
import { toPng } from 'html-to-image';
import { Document, Packer, Paragraph, AlignmentType, ImageRun, Table, TableRow, TableCell, WidthType } from 'docx';
import { saveAs } from 'file-saver';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import ImageModule from 'docxtemplater-image-module-free';
import { DOMParser } from '@xmldom/xmldom';
import JSZipUtils from 'jszip-utils';


let loadOnce = false;

const Index = () => {
    const dispatch = useDispatch()
    const dashboardStore = useSelector((state) => state.dashboard)
    const [localState, setlocalState] = useState({charts: []})
    const [selectionsState, setSelectionState] = useState({target: {value: -1}})
    const [newGraphsState, setNewGraphsState] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingGraphs, setLoadingGraphs] = useState(false)
    const [error, setError] = useState('')
    const [panelLoadingStatus, setPanelLoadingStatus] = useState([])
    const hasSelectionChanged = useHasChanged(selectionsState)
    const [httpStatusCodes, setHttpStatusCodes] = useState({});

    const options = {
        rootMargin: '10px 0px', // like css property
        threshold: 1.0
    }

    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                let id = selectionsState.target.value
                let graphId = entry.target.id.split('panel_placeholder')[1].split('-')
                const filteredPanel = dashboardStore.panels.find((e) => Number(e.id) === Number(id))
                const graph = filteredPanel.data.find((g) => g.id === Number(graphId[1]) && g.chart_type === graphId[2])
                loadPanel(graph)
                observer.unobserve(entry.target);
            }
        });
    }, options);

    useEffect(() => {
        if (dashboardStore.panels.length > 0) {
            setLoadPanelsbase(dashboardStore.panels[0].id)
            setSelectionState({target: {value: dashboardStore.panels[0].id}})
        } else {
            setLoading(() => true)
            if (!loadOnce) {
                loadOnce = true
                setError(() => '')
                dispatchPanels()
            } else {
                setLoading(() => false)
                setError(() => '')

                if (dashboardStore.panels.length === 0) {
                    setError(() => loadOnce ? 'Dashboard' : '')
                } else {
                    setSelectionState({target: {value: dashboardStore.panels[0].id}})
                }
            }
        }
    }, [dashboardStore.panels])

    useEffect(() => {
        if (selectionsState.target.value > 0 && hasSelectionChanged) {
            resetStates()
            setLoadPanelsbase(selectionsState.target.value)
            setLoadingGraphs(() => true)
            loadDashboard()
        }
    }, [selectionsState])

    useEffect(() => {
        if (newGraphsState.length > 0) {
            prepareData()
            setError(() => '')
        } else {
            if (!loadingGraphs && !loadOnce) {
                setError(() => 'Panel')
            }
        }
    }, [newGraphsState])

    
    // FUNCIONALIDAD DE DESCARGA DE DASHBOARD CON PLANTILLA INSTRA

    // Parseador de base64 para imagenes
    const base64Regex = /^(?:data:)?image\/(png|jpg|jpeg|svg|svg\+xml);base64,/;
    const validBase64 = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

    function base64Parser(tagValue) {
        if (typeof tagValue !== "string" || !base64Regex.test(tagValue)) {
            return false;
        }

        const stringBase64 = tagValue.replace(base64Regex, "");

        if (!validBase64.test(stringBase64)) {
            throw new Error("Error parseando tus datos en base64, contiene caracteres invalidos");
        }

        // For browsers, return a string (of binary content)
        const binaryString = window.atob(stringBase64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes.buffer;
    }

    // Funcion descargar dashboard como word CON plantilla 
    const downloadDashboardAsWordTemplate = async () => {
        try {
            const charts = document.querySelectorAll('.chart-container');
            const imgCharts = [];

            for (let chart of charts) {
                // Ocultar las descripciones nuestras para solo mostrar las de Instra antes de generar las imagenes
                const descriptions = chart.querySelectorAll('.chart-description');
                descriptions.forEach(desc => desc.style.display = 'none');
                // Generar las imagenes
                const dataUrl = await toPng(chart);
                imgCharts.push(dataUrl);
            }
    
            JSZipUtils.getBinaryContent('plantilla.docx', (err, content) => {
                if (err) {
                    throw err;
                }
    
                const zip = new PizZip(content);

                const imageOpts = {
                    getImage: (tagValue) => {
                        const imageBuffer = base64Parser(tagValue);
                        console.log('imageBuffer:', imageBuffer); 
                        return imageBuffer;
                    },
                    getSize: (img, tagValue, tagName, context) => {
                        return [600, 400]; 
                    }
                };

                const imageModule = new ImageModule(imageOpts);
                const doc = new Docxtemplater(zip, {
                    modules: [imageModule],
                    paragraphLoop: true,
                    linebreaks: true,
                });

                // Los indicadores en la plantilla están tal que {%donut_aves} etc, y en cada uno de ellos se sustituirá por 
                // las imagenes siguientes
                doc.setData({
                    donut_aves: imgCharts[1], 
                    histograma_distancias: imgCharts[3],
                    boxplot_distancias: imgCharts[4],
                    histograma_distancia_minima: imgCharts[0],
                    heatmap_distancias: imgCharts[7],
                    barchart_aves: imgCharts[2],
                    score_medio: imgCharts[5],
                    distribucion_scores: imgCharts[6]
                });
    
                try {
                    doc.render();
                } catch (error) {
                    console.error('Error rendering the document:', error);
                    throw error;
                }
    
                const out = doc.getZip().generate({
                    type: 'blob',
                    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });
    
                // Descargar el documento modificado
                saveAs(out, 'template_dashboard.docx');
            });
    } catch (error) {
        console.error('Error durante el proceso:', error);
    }
    };

    const getHttpStatusCode = async (graph) => {
        try {
            const data = await dispatch(dashboardDataRequestThunk({ id: graph.id, type: graph.chart_type })).unwrap();
            return data.http_status_code;
        } catch (error) {
            return error.http_status_code || 500; // Código de error genérico si no hay http_status_code
        }
    };

    const handlePanelError = async (panel) => {
        const statusCode = await getHttpStatusCode(panel);
        setHttpStatusCodes(prevState => ({
            ...prevState,
            [panel.id]: statusCode
        }));
    };

    useEffect(() => {
        if (dashboardStore.panels !== '' && selectionsState.target.value >= 0) {
            const selectedPanel = dashboardStore.panels[getPanelIndex(selectionsState.target.value)];
            selectedPanel.data.forEach(panel => {
                handlePanelError(panel);
            });
        }
    }, [selectionsState.target.value, dashboardStore.panels]);
    const defaultDescriptions = [
        "En este gráfico se muestra el histograma de las distancias mínimas a las que se llegan a acercar las aves al aerogenerador.",
        "Se muestra el porcentaje de aves detectadas según el género, familia u orden (categorías taxonómicas de menor a mayor). Las aves no identificadas se muestran como 'otra ave'.",
        "",
        "",
        "Se muestra la distribución de detecciones en función de la distancia. De esta forma se podrán identificar valores extremos de detección.",
        "Puntuación de la precisión, en cada cámara, de identificación del ave que entrega el algoritmo de Gradiant.",
        "Puntuación, según la categoría, que entrega el algoritmo de Gradiant con respecto a la precisión de la identificación del ave.",
        "Muestra las coordenadas, dentro de la imagen, en las que ocurren más o menos detecciones."
    ];    

    return (
        <div id="dashboard-page" className="page-container dashboard">
            <div className="dashboard-inner">
                <div className="selection-wrapper">
                    <div className='selection-wrapper-inner-margins'>
                        <div className="dashboard-header">
                            <h3>Gráficas disponibles</h3>
                            <Button onClick={downloadDashboardAsWordTemplate} className="top-right-button">
                                Download
                            </Button>
                            <Restart onClick={() => reloadPanels()} size={24} className={dashboardStore.loadingDashboard ? "reload-icon rotate-all" : "reload-icon"} />
                        </div>
                        {!loading &&
                            <>
                                <div className="selector-inner">
                                    {!dashboardStore.loadingDashboard &&
                                        <>
                                            <Select
                                                defaultValue="placeholder-item"
                                                helperText="Los paneles se configuran en administración"
                                                id="select-1"
                                                labelText="Seleccione un panel"
                                                size="lg"
                                                className="dashboard-selector"
                                                onChange={(e) => setSelectionState(e)}
                                                disabled={dashboardStore.panels.length === 0}
                                            >
                                                {dashboardStore.panels !== '' ? dashboardStore.panels.map((panel) => {
                                                    return <SelectItem key={panel.id} text={`${panel.name.toUpperCase()} | ${panel.data.length} paneles`} value={panel.id} />
                                                }) : ''}
                                            </Select>
                                        </>
                                    }
                                </div>
                            </>
                        }
                        {loading &&
                            <div className="status-container">
                                <Loading description="Active loading indicator" withOverlay={false} />
                            </div>
                        }</div>
                </div>

                <div className="marker-wrapper">
                    <Grid fullWidth={true} className="markers-container">
                        {dashboardStore.panels !== '' && selectionsState.target.value >= 0
                            && dashboardStore.panels[getPanelIndex(selectionsState.target.value)].data.length > 0 &&
                            <>
                                {dashboardStore.panels[getPanelIndex(selectionsState.target.value)].data
                                    .filter(panel => panel.chart_type === 'marker')
                                    .map((panel) => 
                                    <Column lg={panel.size ? panel.size : '8'} xlg={panel.size ? panel.size : '8'} sm={4} md={8} key={panel.name + "-" + panel.chart_type + "#" + panel.id}>
                                        <>
                                            {getGraphIndex(panel.id, panel.chart_type) > -1 ?
                                            !localState.charts[getGraphIndex(panel.id, panel.chart_type)].error ?
                                                <div className="marker-container">
                                                    {chartChooser(localState.charts[getGraphIndex(panel.id, panel.chart_type)])}
                                                </div> :
                                                httpStatusCodes[panel.id] === 404 ?
                                                    <ErrorPanel404
                                                        panel={panel}
                                                        reloadPanel={reloadPanel}
                                                        loading={panelLoadingStatus[getPanelLoadingIndex(panel.id, panel.chart_type)]?.loading}
                                                    /> :
                                                    <ErrorPanel
                                                        panel={panel}
                                                        reloadPanel={reloadPanel}
                                                        loading={panelLoadingStatus[getPanelLoadingIndex(panel.id, panel.chart_type)]?.loading}
                                                    /> :
                                            <PanelPlaceholder panel={panel}></PanelPlaceholder>
                                        }
                                        </>
                                    </Column>)} 
                            </>
                        }
                    </Grid> 
                </div>
                
                <div className="chart-wrapper">
                    <Grid fullWidth={true} className="charts-container">
                        {dashboardStore.panels !== '' && selectionsState.target.value >= 0
                        && dashboardStore.panels[getPanelIndex(selectionsState.target.value)].data.length > 0 &&
                        <>
                            {dashboardStore.panels[getPanelIndex(selectionsState.target.value)].data
                            .filter(panel => panel.chart_type !== 'marker')
                            .map((panel, index) => {
                                const updatedPanel = {
                                ...panel,
                                description: defaultDescriptions[index] || ""  
                                };

                                return (
                                <Column
                                    lg={updatedPanel.size ? updatedPanel.size : '8'}
                                    xlg={updatedPanel.size ? updatedPanel.size : '8'}
                                    sm={4}
                                    md={8}
                                    key={updatedPanel.name + "-" + updatedPanel.chart_type + "#" + updatedPanel.id}
                                >
                                    <>
                                    {getGraphIndex(updatedPanel.id, updatedPanel.chart_type) > -1 ?
                                        !localState.charts[getGraphIndex(updatedPanel.id, updatedPanel.chart_type)].error ?
                                        <div className="chart-container">
                                            {chartChooser(localState.charts[getGraphIndex(updatedPanel.id, updatedPanel.chart_type)])}
                                            <div className="chart-description">{updatedPanel.description}</div> {}
                                        </div> :
                                        httpStatusCodes[updatedPanel.id] === 404 ?
                                            <ErrorPanel404
                                            panel={updatedPanel}
                                            reloadPanel={reloadPanel}
                                            loading={panelLoadingStatus[getPanelLoadingIndex(updatedPanel.id, updatedPanel.chart_type)]?.loading}
                                            /> :
                                            <ErrorPanel
                                            panel={updatedPanel}
                                            reloadPanel={reloadPanel}
                                            loading={panelLoadingStatus[getPanelLoadingIndex(updatedPanel.id, updatedPanel.chart_type)]?.loading}
                                            /> :
                                        <PanelPlaceholder panel={updatedPanel}></PanelPlaceholder>
                                    }
                                    </>
                                </Column>
                                );
                            })}
                        </>
                        }
                    </Grid>
                </div>
                
                <div className='error-wrapper'>
                    {error !== '' &&
                        <div className="no-content-wrapper">
                            <div className="no-content-wrapper-inner">
                                <h3>No tiene ningún {error} configurado</h3>
                                <FaceDizzy size={32} />
                            </div>
                            <h4>Para configurar, acceda a la pantalla de administración</h4>
                        </div>
                    }
                </div>
            </div>
        </div>
    );

    

    function setLoadPanelsbase(dashboardId) {
        const panels = dashboardStore.panels[getPanelIndex(dashboardId)].data.map(e => {
            return {...e, loading: false}
        })
        setPanelLoadingStatus(() => panels)
    }

    function getPanelIndex(panelId) {
        return dashboardStore.panels.findIndex(e => e.id === Number(panelId))
    }

    function getGraphIndex(panelId, chart_type) {
        return localState.charts.findIndex(e => e.options.id === Number(panelId) && e.type === chart_type)
    }

    function getPanelLoadingIndex(panelId, chart_type) {
        return panelLoadingStatus.findIndex(e => e.id === Number(panelId) && e.chart_type === chart_type)
    }

    function getNewGraphIndex(panelId, chart_type) {
        return newGraphsState.findIndex(e => e.graph_info.id === Number(panelId) && e.graph_info.chart_type === chart_type)
    }

    function resetStates() {
        setlocalState(() => ({charts: []}))
        setNewGraphsState(() => [])
        setPanelLoadingStatus(() => [])
    }

    function reloadPanels() {
        setLoading(() => true)
        resetStates()
        dispatchPanels()
    }

    function reloadPanel(panel) {
        togglePanelLoadingStatus(panel, true)
        loadPanel(panel)
    }

    function togglePanelLoadingStatus(panel, state) {
        if (panelLoadingStatus.length === dashboardStore.panels[getPanelIndex(selectionsState.target.value)].data.length) {
            const newState = panelLoadingStatus.map((e) => {
                if (e.id === panel.id && e.chart_type === panel.chart_type) {
                    return {
                        ...e, loading: state
                    }
                }
                return e
            })
            setPanelLoadingStatus(() => newState)
        }
    }

    function loadPanel(graph) {
        let dta
        let error
        dispatch(dashboardDataRequestThunk({id: graph.id, type: graph.chart_type})).unwrap().then((data) => {
            dta = [data.data]
            error = false
            insertGraph(graph, dta, error)
        }).catch((data) => {
            dta = []
            error = true
            dispatch(pushNotification(new NotificationModel(`Error cargando panel "${graph.name}"`,
                `HTTP error: ${data.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                'error').toJson()))
            insertGraph(graph, dta, error)
        }).finally(() => {
            togglePanelLoadingStatus(graph, false)
            setLoadingGraphs(() => false)
        })
    }

    function insertGraph(graph, data, error) {
        const index = getNewGraphIndex(graph.id, graph.chart_type)
        
        if (index > -1) {
            const newState = newGraphsState.map((e) => {
                if (e.graph_info.id === graph.id && e.graph_info.chart_type === graph.chart_type) {
                    return {
                        ...e, graph_info: graph, data: data, error: error
                    }
                }
                return e
            })
            setNewGraphsState(() => [...newState])
        } else {
            setNewGraphsState((state) => [...state, {
                    graph_info: graph,
                    data: data,
                    error: error
                }]
            )
        }
    }

    function dispatchPanels() {
        dispatch(dashboardOptionsRequestThunk()).unwrap().then((e) => {
            if (e.length === 0) {
                setError(() => 'Dashboard')
            } else {
                setError(() => '')
            }
        }).catch(e => {
            dispatch(pushNotification(new NotificationModel('Error recargando los dashboards',
                `HTTP error: ${e.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                'error').toJson()))
        }).finally(() => {
            setLoading(() => false)
        })
    }

    function loadDashboard() {
        const id = selectionsState.target.value
        setlocalState(() => ({charts: []}))
        if (!isNaN(id)) {
            const filteredPanel = dashboardStore.panels.find((e) => Number(e.id) === Number(id))
            if (filteredPanel && filteredPanel !== '') {
                if (filteredPanel.data.length === 0) {
                    setError(() => 'Panel')
                }
                for (const graph of filteredPanel.data) {
                    let target = document.querySelector(`#panel_placeholder-${graph.id}-${graph.chart_type}`);
                    observer.observe(target);
                }
            }
            setLoadingGraphs(() => false)
        }
    }

    function prepareData() {
        setlocalState(() => ({charts: []}));
        let charts = [];
        for (const chart of newGraphsState) {
            let processed = {};
            let options = {};
            if(chart.graph_info.chart_type!="marker"){
                options = JSON.parse(JSON.stringify(optionsList[chart.graph_info.chart_type])); // Clonado profundo de las opciones
            }
            processed = prepareChart(chart, options);
            processed.opt.id = chart.graph_info.id;
            charts.push(new ChartModel(processed.opt.type, processed.opt, processed.dta, chart.error));
        }
        setlocalState(() => ({charts: [...charts]}));
    }
    


}

export default Index
