import {Edit, FaceDissatisfied} from "@carbon/icons-react";
import {Button, ComposedModal, Form, Loading, ModalBody, ModalFooter, Stack, TextInput} from "@carbon/react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NotificationModel} from "../../Models/Notification.js";
import {addModal, closeModal, toggleModal} from "../../store/reducers/modalsReducer.js";
import {pushNotification} from "../../store/reducers/notificationsReducer.js";
import {profileRequestThunk, profileUpdateThunk, updateProfileState} from "../../store/reducers/userReducer.js";
import {getModalById, objectIsEmpty} from "../../Utils/utils.js";
import FileUploader from "../DataIngressSection/FileUploader/index.js";
import ModalStateManager from "../Modal/index.js";
import defaultProfileIcon from "./default.jpg"

let loadOnce = false;
const Index = () => {
    const dispatch = useDispatch();
    const userStore = useSelector((state) => state.user)
    const [userState, setuserState] = useState({...userStore.profile.user})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [fileState, setFileState] = useState({filename: '', file: ''})
    const modal = useSelector((state) => state.modals)
    const startUp = true
    const modalProfile = 'profile'
    const modalIconEdit = 'iconEdit'
    useEffect(() => {
        if (objectIsEmpty(userState) || !loadOnce) {
            if (objectIsEmpty(userState)) {
                setLoading(() => true)
            }
            setError(() => false)
            dispatch(profileRequestThunk()).then((e) => {
                if ((e.payload.length === 0) || (objectIsEmpty(userState) && e.error)) {
                    setError(() => true)
                }
                setLoading(() => false)
            })
            loadOnce = true
        }
    }, [startUp])
    useEffect(() => {
        setuserState(() => ({...userStore.profile.user}))
    }, [userStore])
    useEffect(() => {
        dispatch(addModal(modalProfile))
        dispatch(addModal(modalIconEdit))
    }, [modal])
    const modalService = ModalStateManager(
        {
            children:
                <>
                    {userStore.profile.user &&
                        <ComposedModal
                            open={getModalById(modal, modalProfile) ? getModalById(modal, modalProfile).state : false}
                            onClose={() => dispatch(closeModal(modalProfile))} size="md">
                            <ModalBody>
                                <h2>Editar Perfil</h2>
                                <Form>
                                    <Stack>
                                        <TextInput labelText='Nombre usuario' id='input-username'
                                                   defaultValue={userStore.profile.user.username}
                                                   onInput={(e) => getFormData(e, 'username')}
                                        />
                                        <TextInput labelText='Nombre' id='input-first-name'
                                                   defaultValue={userStore.profile.user.first_name}
                                                   onInput={(e) => getFormData(e, 'first_name')}
                                        />
                                        <TextInput labelText='Apellidos' id='input-last-name'
                                                   defaultValue={userStore.profile.user.last_name}
                                                   onInput={(e) => getFormData(e, 'last_name')}
                                        />
                                    </Stack>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    kind="danger"
                                    onClick={() =>
                                        dispatch(closeModal(modalProfile))
                                    }>
                                    Cancelar
                                </Button>
                                <Button
                                    kind="primary"
                                    onClick={() =>
                                        updateProfile()
                                    }>
                                    Guardar
                                </Button>
                            </ModalFooter>
                        </ComposedModal>
                    }
                </>
        }
    )

    const modalServiceIcon = ModalStateManager(
        {
            children:
                <>
                    <ComposedModal
                        open={getModalById(modal, modalIconEdit) ? getModalById(modal, modalIconEdit).state : false}
                        onClose={() => dispatch(closeModal(modalIconEdit))} size="md">
                        <ModalBody>
                            <h2>Editar Foto de Perfil</h2>
                            <FileUploader
                                types={['.png', '.jpg', '.jpeg']}
                                item={''}
                                key={'profile-icon-edit'}
                                output={(returnValue) => setFileState((state) => ({
                                    ...state, ...{filename: returnValue.filename, file: returnValue.file}
                                }))} inModal={true}></FileUploader>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                kind="danger"
                                onClick={() =>
                                    dispatch(closeModal(modalIconEdit))
                                }>
                                Cancelar
                            </Button>
                            <Button
                                kind="primary"
                                onClick={() => {
                                    console.log('hola')
                                }
                                }>
                                Guardar
                            </Button>
                        </ModalFooter>
                    </ComposedModal>
                </>
        }
    )


    return (
        <div className="page-container">
            <div className="profile-container">
                {loading &&
                    <>
                        <div className="status-container">
                            <Loading
                                description="Active loading indicator" withOverlay={false}
                            />

                        </div>
                    </>
                }
                {error &&
                    <>
                        <div className="status-container">
                            <h2>Error en la carga del perfil!!!</h2>
                            <FaceDissatisfied size={32}/>
                        </div>
                    </>
                }
                {userStore.profile.user &&
                    <>
                        <div className="avatar-flip">
                            <img onClick={() => dispatch(toggleModal(modalIconEdit))}
                                 src={userStore.profile.avatar ? userStore.profile.avatar : defaultProfileIcon}
                                 height="150" width="150" alt="profile-icon" className={'pointer'}/>
                        </div>
                        <h2>{userStore.profile.user.username}</h2>
                        <hr/>
                        <div className="user-info-container">
                            <div className="user-info-item">
                                <h5>Nombre:</h5>
                                <span>{userStore.profile.user.first_name}</span>
                            </div>
                            <div className="user-info-item">
                                <h5>Apellidos:</h5>
                                <span>{userStore.profile.user.last_name}</span>
                            </div>
                            <div className="user-info-item">
                                <h5>Email:</h5>
                                <span>{userStore.profile.user.email}</span>
                            </div>
                            <div className="profile-actions-container">
                                <Button className="edit-profile" onClick={() => dispatch(toggleModal(modalProfile))}>
                                    <span>Editar Perfil</span>
                                    <Edit className="edit-action"/>
                                </Button>
                            </div>
                        </div>

                        {modalService}
                        {modalServiceIcon}
                    </>
                }
            </div>
        </div>)

    function getFormData(e, type) {
        const value = {}
        switch (type) {
            case 'username':
                value.username = e.target.value
                break
            case 'first_name':
                value.first_name = e.target.value
                break
            case 'last_name':
                value.last_name = e.target.value
                break
            default:
                break
        }
        setuserState(() => ({...userState, ...value}))
    }

    function updateProfile() {
        dispatch(closeModal(modalProfile))
        dispatch(updateProfileState(userState))
        dispatch(profileUpdateThunk()).then((r) => {
            if (r.error) {
                dispatch(pushNotification(new NotificationModel('Error en la actualización',
                    `HTTP error: ${r.payload.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                    'error').toJson()))
            }
            dispatch(closeModal())
        })
    }
}

export default Index
